import { DestroyRef, Directive, ElementRef, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AgGridAngular } from 'ag-grid-angular';
import { ColumnMovedEvent } from 'ag-grid-community';
import { asyncScheduler, merge, of, scheduled } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

type GridConfigMap = {
  [gridId: string]: GridConfig;
};

type GridConfig = {
  columnIndex: {
    [columnId: string]: number;
  };
};

const enum ChangeOrigin {
  ROW_DATA_UPDATE = 'ROW_DATA_UPDATE',
  SIZE_CHANGE = 'SIZE_CHANGE',
}

/**
 * @description Controla o redimensionamento das colunas de maneira automática.
 * Armazena e restaura a posição das colunas definidas pelo usuário.
 *
 * @author Noah Correa <valdecir.correa@nexuscloud.com.br>
 */
@Directive({
  standalone: true,
  selector: 'ag-grid-angular[agGridResizeApi]',
})
export class AgGridResizeApiDirective {
  private gridConfigKey = 'grid_config';

  private rowDataUpdated = false;

  constructor(
    private ngZone: NgZone,
    private destroyRef: DestroyRef,
    private agGridComponent: AgGridAngular,
    private agGridElement: ElementRef<HTMLElement>
  ) {
    agGridComponent.gridReady.subscribe(this.onGridReady.bind(this), takeUntilDestroyed());
  }

  private onGridReady(): void {
    this.ngZone.runOutsideAngular(() => {
      const rowDataUpdated = this.agGridComponent.rowDataUpdated.pipe(
        map(() => {
          this.rowDataUpdated = true;
          return ChangeOrigin.ROW_DATA_UPDATE;
        })
      );

      const sizeChanged = this.agGridComponent.gridSizeChanged.pipe(
        map(event => event.clientWidth),
        distinctUntilChanged(),
        map(() => ChangeOrigin.SIZE_CHANGE)
      );

      merge(rowDataUpdated, sizeChanged)
        .pipe(
          debounceTime(50),
          startWith(ChangeOrigin.SIZE_CHANGE),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(this.resizeColumns.bind(this));

      this.agGridComponent.columnMoved
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.storeColumnPosition.bind(this));

      scheduled(of('Posicionar colunas'), asyncScheduler)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.positionColumnsAsConfig.bind(this));
    });
  }

  private resizeColumns(): void {
    if (!this.isGridVisible()) return;

    if (this.rowDataUpdated) {
      this.setColumnsMinWidth();
      this.rowDataUpdated = false;
    }

    this.resizeColumnsToFit();
  }

  private resizeColumnsToFit(): void {
    this.agGridComponent.api.sizeColumnsToFit({
      columnLimits: this.agGridComponent.api.getColumns().map(column => {
        return {
          key: column.getColId(),
          minWidth: column.getColDef().minWidth,
        };
      }),
    });
  }

  private setColumnsMinWidth(): void {
    this.agGridComponent.api.autoSizeAllColumns();
    this.agGridComponent.api.getColumns().forEach(column => {
      column.getColDef().minWidth = column.getActualWidth();
    });
  }

  private positionColumnsAsConfig(): void {
    const gridId = this.getGridId();
    const gridConfig = this.getGridConfig(gridId);

    Object.entries(gridConfig.columnIndex)
      .sort((a, b) => a[1] - b[1])
      .forEach(([columnId, index]) => {
        this.agGridComponent.api.moveColumn(columnId, index);
      });
  }

  private storeColumnPosition(event: ColumnMovedEvent): void {
    if (!event.column) return;

    const gridId = this.getGridId();
    const columnId = event.column.getId();
    const columState = event.api.getColumnState();
    const gridConfig = this.getGridConfig(gridId);
    gridConfig.columnIndex[columnId] = event.toIndex;

    Object.keys(gridConfig.columnIndex).forEach(colId => {
      gridConfig.columnIndex[colId] = columState.findIndex(state => state.colId === colId);
    });

    this.storeConfig(gridId, gridConfig);
  }

  private getGridConfig(gridId: string): GridConfig {
    const configMap = JSON.parse(localStorage.getItem(this.gridConfigKey)) as GridConfigMap;
    return configMap?.[gridId] ?? { columnIndex: {} };
  }

  private storeConfig(gridId: string, config: GridConfig): void {
    const configMap = (JSON.parse(localStorage.getItem(this.gridConfigKey)) || {}) as GridConfigMap;
    configMap[gridId] = config;
    localStorage.setItem(this.gridConfigKey, JSON.stringify(configMap));
  }

  private getGridId(): string {
    const columnsId = this.agGridComponent.api
      .getColumns()
      .map(col => col.getColId())
      .join('');
    return `${this.agGridElement.nativeElement.id}_${btoa(columnsId)}`;
  }

  private isGridVisible(): boolean {
    return this.agGridElement.nativeElement.clientWidth > 0;
  }
}
