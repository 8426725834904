import { Directive, HostListener, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[appTrimValue]',
  standalone: true,
})
export class TrimValueDirective {
  constructor() {}

  @Input('formControl') formControl: UntypedFormControl;

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    $event.target.value = $event.target.value.trim();
    if ($event.target.value === '') {
      if (this.formControl) {
        this.formControl.setErrors({ required: true });
        this.formControl.setValue('');
      }
    } else if (this.formControl) {
      this.formControl.setValue($event.target.value);
    }
  }
}
